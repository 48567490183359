<script setup lang="ts">
import { Icon, Button } from "@/components/Ui";
import { onEvent, onDashboard } from "@/helpers/events";
import { useRouter } from "vue-router";
import {useI18n} from "vue-i18n";
import type { TCard } from "@/config/cards";

const {t} = useI18n();

interface Props {
  card?: TCard;
  hideConditions?: boolean;
  getCardEvent?: string;
  learnMoreEvent?: string;
}

const router = useRouter();
const props = defineProps<Props>();
const localePath = useLocalePath();
const onGetCardHandler = () => {
  if (props.card?.type === "private") {
    onEvent("openPrivateLanding", router.push(localePath(props.card?.learn_more_link)), {
      // "cta type": "button",
      // "cta location": `Card: ${props.card.title}`,
      // "cta text": `${props.card.get_card_title}`
    });
  } else {
    onDashboard(
      props.getCardEvent || "create account started",
      "/register",
      {
        // "cta type": "button",
        // "cta location": `Card: ${props.card.title}`,
        // "cta text": "Get card"
      },
      {},
      true
    );
  }
};
const onLearnMoreHandler = () => {
  const eventName = props.learnMoreEvent || (props.card?.type === "private" ? "openPrivateLanding" : `open${props.card?.type.charAt(0).toUpperCase() + props.card?.type.slice(1)}CardPage`);
  onEvent(eventName, router.push(localePath(props.card?.learn_more_link)), {
    // "cta type": "button",
    // "cta location": `Card: ${props.card.title}`,
    // "cta text": "Learn more"
  });
};
</script>
<template>
  <div v-if="props.card" :class="`${[$style.root]} ${[$style[`root-${props.card.background}`]]}`">
    <ul :class="$style.tags">
      <li
        v-for="(tag, index) in props.card.tags"
        :key="index"
        :class="`${[$style.tag]} ${[$style[`tag-${tag.color}-${props.card.background}`]]}`"
        v-show="tag?.icon !== 'api'"
      >
        <Icon v-if="tag.icon" :name="tag.icon" />
        {{ t(`${tag.title}`).replaceAll("%qty_bins%", `${props.card.qty_bins}`) }}
      </li>

    </ul>

    <div :class="`${[$style.title]} ${[$style[`titleColor-${props.card.background}`]]}`">
      {{ props.card.title }}
    </div>

    <div :class="`${[$style.description]} ${[$style[`textColor-${props.card.background}`]]}`">
      {{ t(`${props.card.description}`) }}
    </div>

    <!--    Conditions-->
    <ul
      v-if="
        !props.hideConditions
      "
      :class="[$style.tariff, $style[`tariff-${$i18n.locale}`]]"
    >
      <template v-if="props.card.type !== 'private'">
        <li v-if="props.card.withdraw_fee">
          <span :class="$style[`titleColor-${props.card.background}`]">
            {{ props.card.withdraw_fee }}
          </span>
          <span :class="$style[`textColor-${props.card.background}`]">
            {{ t("cards.withdraw_fee") }}
          </span>
        </li>
        <li v-if="props.card.transaction_fee">
          <span :class="$style[`titleColor-${props.card.background}`]">
            {{ props.card.transaction_fee }}
          </span>
          <span :class="$style[`textColor-${props.card.background}`]">
            {{ t("cards.transaction_fee") }}
          </span>
        </li>
        <li v-if="props.card.deposit_fee">
          <span :class="$style[`titleColor-${props.card.background}`]">
            {{ props.card.deposit_fee }}
          </span>
          <span :class="$style[`textColor-${props.card.background}`]">
            {{ t("cards.deposit_fee") }}
          </span>
        </li>
        <li v-if="props.card.monthly_payment">
          <span :class="$style[`titleColor-${props.card.background}`]">
            {{ props.card.monthly_payment }}
            <i v-if="props.card.old_monthly_payment" :class="$style['tariff-old']">
              {{ props.card.old_monthly_payment }}
            </i>
          </span>
          <span :class="$style[`textColor-${props.card.background}`]">
            {{ t("cards.monthly_payment") }}
          </span>
        </li>

        <li v-if="props.card.cost_issue">
          <span :class="$style[`titleColor-${props.card.background}`]">
            {{t("label.fromWithValue", {p: props.card.cost_issue}).toLowerCase()}}
          </span>
          <span :class="$style[`textColor-${props.card.background}`]">
            {{ t("cards.cost_issue") }}
          </span>
        </li>
      </template>
      <template v-else>
        <li>
          <span :class="$style[`titleColor-${props.card.background}`]">
            {{ props.card.num_free_cards }}
          </span>
          <span :class="$style[`textColor-${props.card.background}`]">
            {{ t("cards.free_cards") }}
          </span>
        </li>
        <!--
        <li>
          <span :class="$style[`titleColor-${props.card.background}`]">
            {{ props.card.monthly_payment }}
          </span>
          <span :class="$style[`textColor-${props.card.background}`]">
            {{ t("cards.for_card") }}
          </span>
        </li>

        <li>
          <span :class="$style[`titleColor-${props.card.background}`]">
            {{ props.card.deposit_fee }}
          </span>
          <span :class="$style[`textColor-${props.card.background}`]">
            {{ t("cards.fee") }}
          </span>
        </li>
        -->
        <li>
          <span :class="$style[`titleColor-${props.card.background}`]">
            {{ props.card?.cashback }}
          </span>
          <span :class="$style[`textColor-${props.card.background}`]">
            {{ t("cards.cashback") }}
          </span>
        </li>
      </template>
    </ul>

    <div
      v-if="
        props.card.get_card_link ||
        props.card.learn_more_link
      "
      :class="$style.actions"
    >
      <Button
        v-if="props.card.get_card_link"
        :title="(t(`${props.card.get_card_title}`, {n: props.card.num_free_cards})) || t('btn.getCard')"
        size="custom"
        :color="props.card.background === 'light' ? 'dark' : 'white'"
        :class="$style.button"
        @click="onGetCardHandler"
      />
      <Button
        v-if="props.card.learn_more_link"
        :title="t('btn.learnMore')"
        :color="props.card.background === 'light' ? 'transparent' : 'transparent-dark'"
        size="custom"
        :class="$style.button"
        @click="onLearnMoreHandler"
      />
    </div>
    <Icon
      v-if="props.card.svg"
      :name="`pst-${props.card.svg}`"
      path="cards"
      :class="$style.card"
    />
    <nuxt-img v-else :src="`/img/cards/${props.card.image}`" alt="" format="webp" :class="$style.card" />
  </div>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] p-8 md:p-[50px];
  @apply grid items-start grid-flow-row auto-rows-min grid-cols-1 lg:gap-x-[30px] lg:grid-cols-[minmax(200px,1fr)_440px];

  &-light {
    @apply bg-neutral-100;
  }

  &-dark {
    @apply bg-neutral-900;
  }
}

.textColor {
  &-light {
    @apply text-neutral-600;
  }

  &-dark {
    @apply text-neutral-300;
  }
}

.titleColor {
  &-light {
    @apply text-neutral-900;
  }

  &-dark {
    @apply text-white;
  }
}

.title {
  @apply font-semibold text-[26px] mb-[10px] order-3;
}

.description {
  @apply text-[15px] mb-[30px] order-4;
}

.tags {
  @apply flex flex-wrap items-center gap-[10px] mb-10 order-1;
}

.tag {
  @apply w-fit rounded-full py-[9px] px-3 flex items-center gap-2;

  & svg {
    @apply w-6 h-6 min-w-[24px];
  }

  &-grey {
    &-light {
      @apply bg-neutral-200 text-neutral-800;
    }

    &-dark {
      @apply bg-neutral-700 text-white;
    }
  }

  &-orange {
    &-light,
    &-dark {
      @apply text-accent-primary bg-accent-primary bg-opacity-[0.16];
    }
  }
}

.tariff {
  @apply flex flex-row gap-5 lg:w-fit lg:max-w-full mb-10 order-5;

  & > li {
    @apply flex flex-col w-1/3 lg:w-[140px];

    & span {
      &:first-child {
        @apply text-[22px] xxs:text-[25px];
      }

      &:last-child {
        @apply text-[12px] xxs:text-[14px] xs:text-[16px];
      }
    }
  }

  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-bg,
  &-it,
  &-pt,
  &-tr,
  &-ua,
  &-zh,
  &-es,
  &-ru,
  &-fr,
  &-ja,
  &-th,
  &-pl,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi,
  &-sw{
    & > li {
      @apply flex flex-col w-1/3 lg:w-[140px];

      & span {
        &:first-child {
          @apply text-[22px] xxs:text-[25px];
        }

        &:last-child {
          @apply text-[12px] xxs:text-[14px] xs:text-[16px];
        }
      }
    }
  }
  &-de {
    & > li {
      @apply flex flex-col w-1/3 lg:w-[140px];

      & span {
        &:first-child {
          @apply text-[22px] xxs:text-[25px];
        }

        &:last-child {
          @apply text-[12px] xxs:text-[14px] xs:text-[16px] break-all md:break-normal;
        }
      }
    }
  }

  &-old {
    @apply hidden lg:inline-flex opacity-60 not-italic line-through px-2;
  }
}

.actions {
  @apply flex items-center gap-2 xs:gap-4 flex-col md:flex-row order-6 row-end-7;
}

.button {
  @apply text-[15px] py-3 px-5 w-full min-w-fit lg:max-w-[200px];
}

.card {
  @apply w-full max-w-[440px] mb-10 mx-auto lg:m-0 order-2 lg:row-span-6;

  & svg {
    @apply h-full w-full;
  }
}
</style>